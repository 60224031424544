import { store } from "../State/store/configureStore";

export const FEATURES = {
  ADD_PATIENT: "AddPatient",
  ADD_MEMBER: "AddMember",
  PATIENT_COMMUNICATION: "PatientCommunication",
  PUSH_NOTIFICATION_FOR_STATUS_UPDATE: "PushNotificationForStatusUpdate",
  PATIENT_INVITE_MANAGEMENT: "PatientInviteManagement",
  SMS_NOTIFICATION_FOR_ONBOARDING: "SMSNotificationForOnBoarding",
  EMAIL_NOTIFICATION_FOR_ON_BOARDING: "EmailNotificationForOnBoarding",
  TASKS: "Tasks",
  PUSH_NOTIFICATION_FOR_URGENT_TASKS: "PushNotificationForUrgentTasks",
  PUSH_NOTIFICATION_FOR_ALL_TASKS: "PushNotificationForAllTasks",
  HARD_DELETE_ARTIFACTS: "HardDeleteArtifacts",
  CUSTOM_PATIENT_LISTING_ACTIONS: "CustomPatientListingActions",
  FETCH_DETAILED_REPORT: "FetchDetailedReport",
  FETCH_THERAPY_COMPLIANCE_REPORT: "FetchTherapyComplianceReport",
  PACKAGE_IMPORT_EXPORT: "Package_Import_Export",
  REMINDER_SERVICE: "ReminderService",
  EDIT_DELETE_STATUS_MESSAGE: "AllowEditDeleteStatusMessage",
  SUBMIT_SURVEY_FROM_STAFF: "SubmitSurveyFromStaff",
  CUSTOM_PATIENT_REPORT: "CustomPatientReport",
  APPOINTMENT: "Appointment",
  VIDEO_CONFERENCE: "VideoConference",
  IMPORT_PATIENT_CSV: "ImportPatientCSV",
  CARE_TEAM_BASED_VISIBILITY: "CareTeamBasedVisibility",
  UNIQUE_ID_BASED_PATIENT_LOAD: "UniqueIDBasedPatientLoad",
  ADD_STAFF: "AddStaff",
  IMPORT_STAFF_CSV: "ImportStaffCSV",
  ENABLE_PATIENT_APP_IN_WEB: "EnablePatientAppInWeb",
  EXTERNAL_API_ACCESS: "ExternalAPIAccess",
  RULE_ENGINE: "RuleEngine",
  ALLOW_INTERACTIONS: "AllowInteractions",
  ALLOW_PRIVATE_MESSAGES: "AllowPrivateMessages",
  ALLOW_ADHOC_ADD_PATIENT_ON_APPOINTMENT: "AllowAdhocAddPatientOnAppointment",
  HIDE_STAFF_NOTES: "HideStaffNotes",
  HIDE_DASHBOARD: "HideDashboard",
  HIDE_PATIENT_DASHBOARD: "HidePatientDashboard",
  HIDE_STAFF_DASHBOARD: "HideStaffDashboard",
  HIDE_SURVEY_DASHBOARD: "HideSurveyDashboard",
  HIDE_TASKS_DASHBOARD: "HideTasksDashboard",
  SHOW_STAFF_INFO_PANEL: "ShowStaffInfoPanel",
  DISABLE_CHANGE_CP_NORMAL_STAFF: "DisableChangeCarePlanForNormalStaff",
  ENABLE_CLINIC_MANAGEMENT: "EnableClinicManagement",
  EXPORT_PATIENTS_AS_CSV: "ExportPatientsAsCSV",
  DISALLOW_FREE_TEXT_NOTES: "DisallowFreeTextNotes",
  APPOINTMENT_DISABLE_ADHOC_BOOKING: "AppointmentDisableAdhocBooking",
  ALLOW_UNAUTHENTICATED_VIDEO_CALLS: "AllowUnAuthenticatedVideoCalls",
  IMPORT_APPOINTMENT_SCHEDULE_CSV: "ImportAppointmentScheduleCSV",
  ENABLE_AIRVIEW_NOTES: "EnableAirViewNotes",
  MEDICATIONS: "Medications",
  ENABLE_TWOFA_TOTP: "EnableTwoFATOTP",
  ENABLE_TWOFA_AUTHENTICATOR: "EnableTwoFAAuthenticator",
  ENABLE_HCP_REPORTS: "EnableHcpReports",
  ENABLE_CONTENT_LOCALIZATION: "EnableContentLocalization",
  ENABLE_EMBEDDED_REPORTS: "EnableEmbeddedReports",
  REMOVE_CONTACTS_ON_DELETE: "RemoveContactsOnDelete",
  EMBEDDED_ACTIONS: "EmbeddedActions",
  ENABLE_CHARGE_ITEMS: "EnableChargeItems",
  ENABLE_CSV_EXPORT_SURVEY_RESPONSES: "EnableCSVExportOfSurveyResponses",
  BYPASS_ONBOARD_EMAIL_TEL_VALIDATION: "BypassOnboardEmailTelValidation",
  BREAKDOWN_ACTIVE_INACTIVE_TOTALS: "BreakdownActiveInactiveTotals",
  ENABLE_QUICK_ACTIONS_FOR_PATIENT: "EnableQuickActionsForPatient",
  MAKE_PATIENT_LOGINUSERID_PRIVATE: "MakePatientLoginUserIdPrivate",
  ENABLE_NEWGEN_CHARTS: "EnableNewGenCharts",
  DISABLE_COPY_TO_STAFF_NOTES_IN_TASKS: "DisableCopyToStaffNotesInTasks",
  DISALLOW_EDIT_STAFF_NOTES: "DisallowEditStaffNotes",
  ALLOW_PATIENT_CARE_PLAN_EDIT: "AllowEditCarePlan",
  ENABLE_ADHERANCE_REPORT: "EnableAdheranceChart",
  ALLOW_CHAT_WITH_PATIENT_IN_STATUS_MESSAGE: "AllowChatWithPatientInStatusMessage",
  SHOW_EPISODES_OF_CARE: "ShowEpisodesOfCare",
  ENABLE_CONFIGURABLE_CLINIC_COLUMNS: "EnableConfigurableClinicColumns"
};

class FeaturesService {
  static canAddPatient() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ADD_PATIENT) !== -1;
  }

  static canTasks() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.TASKS) !== -1;
  }

  static canReminder() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.REMINDER_SERVICE) !== -1;
  }

  static canDoAppointments() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.APPOINTMENT) !== -1;
  }

  static canStaffSubmitSurvey() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.SUBMIT_SURVEY_FROM_STAFF) !== -1
    );
  }

  static canHaveCareTeamBasedVisibility() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.CARE_TEAM_BASED_VISIBILITY) !== -1
    );
  }

  static isUniqueIdBasedPatientLoad() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.UNIQUE_ID_BASED_PATIENT_LOAD) !== -1
    );
  }

  static shouldHideStaffNotes() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.HIDE_STAFF_NOTES) !== -1;
  }

  static shouldHideDashboard() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.HIDE_DASHBOARD) !== -1;
  }

  static shouldHidePatientsDashboard() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.HIDE_PATIENT_DASHBOARD) !== -1;
  }

  static shouldHideStaffDashboard() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.HIDE_STAFF_DASHBOARD) !== -1;
  }

  static shouldHideSurveyDashboard() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.HIDE_SURVEY_DASHBOARD) !== -1;
  }

  static shouldHideTasksDashboard() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.HIDE_TASKS_DASHBOARD) !== -1;
  }

  static canShowStaffInfoPanel() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.SHOW_STAFF_INFO_PANEL) !== -1;
  }

  static membersAllowed() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ADD_MEMBER) !== -1;
  }

  static disableChangeCPForNormalStaff() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.DISABLE_CHANGE_CP_NORMAL_STAFF) !== -1
    );
  }

  static canHaveClinics() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.ENABLE_CLINIC_MANAGEMENT) !== -1
    );
  }

  static showAllowNotesForTasks() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.DISALLOW_FREE_TEXT_NOTES) === -1
    );
  }

  static allowAdhocBooking() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.APPOINTMENT_DISABLE_ADHOC_BOOKING) === -1
    );
  }

  static allowUnAuthVideoCalls() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.ALLOW_UNAUTHENTICATED_VIDEO_CALLS) !== -1
    );
  }

  static allowImportAppointmentScheduleCSV() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.IMPORT_APPOINTMENT_SCHEDULE_CSV) !== -1
    );
  }

  static allowAirViewNotes() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ENABLE_AIRVIEW_NOTES) !== -1;
  }

  static canDoMedications() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.MEDICATIONS) !== -1;
  }

  static isLocalizationEnabled() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      (features.indexOf(FEATURES.ENABLE_CONTENT_LOCALIZATION) !== -1 ||
        features.indexOf(FEATURES.ENABLE_CONTENT_LOCALIZATION) !== -1)
    );
  }

  static isConsolidatedReportEnabled() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      (features.indexOf(FEATURES.CUSTOM_PATIENT_REPORT) !== -1 ||
        features.indexOf(FEATURES.CUSTOM_PATIENT_REPORT) !== -1)
    );
  }

  static isMFAEnabled() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      (features.indexOf(FEATURES.ENABLE_TWOFA_TOTP) !== -1 ||
        features.indexOf(FEATURES.ENABLE_TWOFA_AUTHENTICATOR) !== -1)
    );
  }

  static hideAppointments() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.APPOINTMENT) === -1;
  }

  static isPatientAppEnabled() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.ENABLE_PATIENT_APP_IN_WEB) !== -1
    );
  }

  static isHCPReportEnabled() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ENABLE_HCP_REPORTS) !== -1;
  }

  static doRemoveContactsOnDelete() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.REMOVE_CONTACTS_ON_DELETE) !== -1
    );
  }

  static canUseEmbeddedActions() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.EMBEDDED_ACTIONS) !== -1;
  }

  static enableChargeItems() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ENABLE_CHARGE_ITEMS) !== -1;
  }

  static canShowPatientStatsBreakdown() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.BREAKDOWN_ACTIVE_INACTIVE_TOTALS) !== -1
    );
  }

  static areEmbeddedReportsEnabled() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.ENABLE_EMBEDDED_REPORTS) !== -1
    );
  }

  static enablePatientQuickActions() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.ENABLE_QUICK_ACTIONS_FOR_PATIENT) !== -1
    );
  }

  static hidePatientLoginUserId() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.MAKE_PATIENT_LOGINUSERID_PRIVATE) !== -1
    );
  }

  static useNewCharting() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ENABLE_NEWGEN_CHARTS) !== -1;
  }
  static canCopyToStaffNotesInTasks() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features &&
      features.indexOf(FEATURES.DISABLE_COPY_TO_STAFF_NOTES_IN_TASKS) === -1
    );
  }
  static canEditStaffNotes() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.DISALLOW_EDIT_STAFF_NOTES) === -1
    );
  }
  static showAdheranceReport() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.ENABLE_ADHERANCE_REPORT) !== -1
    );
  }
  static showChatInStatusMessage() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.ALLOW_CHAT_WITH_PATIENT_IN_STATUS_MESSAGE) !== -1
    );
  }

  static showEpisodesOfCare() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.SHOW_EPISODES_OF_CARE) !== -1
    );
  }

  static showClinicColumns() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.ENABLE_CONFIGURABLE_CLINIC_COLUMNS) !== -1
    );
  }
}

export default FeaturesService;
