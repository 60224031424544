// @flow

import {
  LOAD_CONTENT_LIBRARY,
  CONTENT_LIBRARY_LOADED,
  CONTENT_LIBRARY_RESET_LOADING,
  CONTENT_ADD_DONE,
  CONTENT_ADD_ERROR,
  CONTENT_LIBRARY_TOPICS_LOADED,
  LOAD_CONTENT_LIBRARY_TOPICS,
  ACTIVE_LIBRARY_ITEM,
  ONLY_CONTENT_LIBRARY_RESET_LOADING,
  SET_LIBRARY_FILTERS,
  LOG_OUT,
} from "../actions/actionTypes";

const initialState = {
  initialLoad: false,
  loading: false,
  list: [],
  topics: [],
  topicsMap: {},
  loadMore: false,
  totalItems: 0,
  page: 0,
  // error info
  error: false,
  errorMsg: "",
  filter: { status: "ACTIVE" },
};

const contentLibraryReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case CONTENT_LIBRARY_RESET_LOADING:
    case CONTENT_ADD_DONE:
      return { ...initialState, contentAdded: action.data };
    
    case SET_LIBRARY_FILTERS:
      newState = {...state, filter:{...state.filter,...action.filter}};
      return newState;

    case ONLY_CONTENT_LIBRARY_RESET_LOADING:
      return {
        ...state,
        loading: false,
        list: [],
        loadMore: false,
        totalItems: 0,
        page: 0,
        error: false,
        errorMsg: ""
      };

    case CONTENT_ADD_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case LOAD_CONTENT_LIBRARY:
    case LOAD_CONTENT_LIBRARY_TOPICS:
      return { ...state, loading: true };

    case CONTENT_LIBRARY_LOADED:
      // console.log('statuses loaded', action);
      if (action.hardReload === "first") {
        return {
          ...initialState,
          loading: false,
          page: action.page,
          initialLoad: true,
          loadMore: action.loadMore,
          list: action.list,
          totalItems: action.totalItems
        }
      }

      newState = {
        // ...state,
        // loading: false,
        // loadMore: false,
        // list: [].concat(action.list),

        ...state,
        loading: false,
        page: action.page,
        initialLoad: true,
        loadMore: action.loadMore,
        list: action.hardReload ? action.list : [...state.list].concat(action.list),
        totalItems: action.totalItems
      };
      // console.log('REDUCER STATUSES ', newState);
      return newState;

    case CONTENT_LIBRARY_TOPICS_LOADED:
      // console.log('statuses loaded', action);

      let topicsMap = {};
      action.topics.forEach(topic => {
        topicsMap[topic.topicId] = topic;
      });
      return Object.assign({}, state, {
        topics: action.topics,
        topicsMap: topicsMap,
        loading: false
      });

    case ACTIVE_LIBRARY_ITEM:
      return {
        ...state,
        activeLibraryItemId: action.id
      };

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default contentLibraryReducer;
