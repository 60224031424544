import {
  LOAD_CONTENT_LIBRARY,
  CONTENT_LIBRARY_LOADED,
  CONTENT_ADD,
  CONTENT_ADD_DONE,
  CONTENT_ADD_ERROR,
  EDIT_CONTENT,
  EDIT_CONTENT_DONE,
  EDIT_CONTENT_ERROR,
  LOAD_CONTENT_LIBRARY_TOPICS,
  CONTENT_LIBRARY_TOPICS_LOADED,
  TOPIC_ADD,
  TOPIC_ADD_DONE,
  TOPIC_ADD_ERROR
} from "../actions/actionTypes";
import { put, select, take } from "redux-saga/effects";
import ContentLibraryService from "../../services/contentLibraryService";
import { goBack } from "../../history";

export function* loadLibrary() {
  while (true) {
    const { hardReload } = yield take(LOAD_CONTENT_LIBRARY);

    // get current page and load more
    const contentLibrary = yield select(state => state.contentLibrary);
    // check if loadMore is true or already loading
    // console.log('load statuses', statuses);

    if (
      !contentLibrary.loading &&
      "loadMore" in contentLibrary &&
      !contentLibrary.loadMore
    ) {
      // load more should not fire as loadMore is false
      return false;
    }

    // get page
    const page = hardReload ? 0 : contentLibrary.page || 0;
    const filter = hardReload === "first" ? { status: "ACTIVE" } : contentLibrary.filter;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_CONTENT_LIBRARY });
    // load statuses
    try {
      const result = yield ContentLibraryService.load(page, filter);
      // console.log('SAGE RESULT RETURNED ', result);

      yield put({
        type: CONTENT_LIBRARY_LOADED,
        list: result.list,
        loadMore: result.loadMore,
        page: page + 1,
        totalItems: result.totalItems,
        hardReload
      });
    } catch (error) {
      console.log(error);
      yield put({
        type: CONTENT_LIBRARY_LOADED,
        list: [],
        loadMore: false,
        page: page + 1
      });
      // console.log('ERROR', error);
    }
  }
}

export function* addLibraryContent() {
  while (true) {
    const action = yield take(CONTENT_ADD);

    try {
      const result = yield ContentLibraryService.add(action.data);
      yield put({
        type: CONTENT_ADD_DONE,
        data: { libraryId: result.id, libraryName: result.name }
      });
    } catch (error) {
      yield put({ type: CONTENT_ADD_ERROR, error: error.toString() });
    }
  }
}

export function* editLibraryContent() {
  while (true) {
    const action = yield take(EDIT_CONTENT);

    try {
      const result = yield ContentLibraryService.edit(
        action.contentId,
        action.data
      );
      yield put({ type: EDIT_CONTENT_DONE });
      goBack("/content-library/" + action.contentId + "/edit");
    } catch (error) {
      yield put({ type: EDIT_CONTENT_ERROR, error: error.toString() });
    }
  }
}

export function* loadLibraryTopics() {
  while (true) {
    const action = yield take(LOAD_CONTENT_LIBRARY_TOPICS);
    yield put({ type: LOAD_CONTENT_LIBRARY_TOPICS });
    try {
      const result = yield ContentLibraryService.loadTopics(
        action.patientTypeId
      );
      // console.log('SAGE RESULT RETURNED ', result);
      yield put({
        type: CONTENT_LIBRARY_TOPICS_LOADED,
        topics: result
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: CONTENT_LIBRARY_TOPICS_LOADED,
        topics: []
      });
      // console.log('ERROR', error);
    }
  }
}

export function* addTopic() {
  while (true) {
    const action = yield take(TOPIC_ADD);

    try {
      const result = yield ContentLibraryService.addTopic(action.data);
      yield put({ type: TOPIC_ADD_DONE });
      goBack("/content-library/topics");
    } catch (error) {
      yield put({ type: TOPIC_ADD_ERROR, error: error.toString() });
    }
  }
}
