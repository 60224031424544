import React, { Component } from "react";
import { Input } from "reactstrap";
import moment from "moment";

class DateObservation extends Component {
  static getDisplayValue(attributeValue, valuePlace) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      let valueDate = null;
      if (valuePlace === "from") {
        valueDate = attributeValue.valueObservation.value.valueFromDate;
      } else if (valuePlace === "to") {
        valueDate = attributeValue.valueObservation.value.valueToDate;
      } else {
        valueDate = attributeValue.valueObservation.value.valueDate;
      }

      return valueDate
        ? moment(valueDate).format("YYYY-MM-DD")
        : "";
    }
  }

  static getPatientProfileDisplayValue(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      let valueDate = attributeValue.valueObservation.value.valueDate;

      if (valueDate) {
        return new Date(valueDate).toLocaleDateString();
      } else {
        let valueString = attributeValue.valueObservation.value.valueString;
        if (valueString) {
          return moment(valueString, "YYYYMMDD").toDate().toLocaleDateString();
        }
      }
    }
  }

  static getValueForSubmission(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value &&
      attributeValue.valueObservation.value.valueDate
    ) {
      attributeValue.valueObservation.value.valueDate = new Date(
        attributeValue.valueObservation.value.valueDate
      ).getTime();
    }
    return attributeValue;
  }

  render() {
    let { attributeValue, setData, observationCode } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueDate;
    attributeValue.valueObservation.code = observationCode;

    return (
      <div className="inputHolder flex-row">
        <Input
          className="ui-attribute-el"
          defaultValue={obsVal ? moment(obsVal).format("YYYY-MM-DD") : null}
          // value={moment(obsVal).format("YYYY-MM-DD")}
          onChange={(e) => {
            attributeValue.valueObservation.value.valueDate = e.target.value;
            attributeValue.valueObservation.value.valueString = null;
            attributeValue.valueObservation.value.valueAsString = null;
            setData(attributeValue, e.target.value);
          }}
          type="date"
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default DateObservation;
