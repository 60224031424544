import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import VideoComponent from "./VideoComponent";
import { Card, CardBody, Container } from "reactstrap";
import MPSBarLoader from "../MPSBarLoader";
import MPSButton from "../Buttons/Buttons/MPSButton";
import moment from "moment";
import isIOSChromeOrSafari, { doPollingToJoinCall } from "./Utils";
import CallWaitTimer from "./CallWaitTimer";
import userService from "../../services/userService";
import GoToHome from "./GoToHome";
import UserIcon from "./UserIcon";
import PatientWebexMeeting from "./WebEx/Patient/PatientWebexMeeting";

import I18nProvider from "../../services/I18nProvider";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";

const intl = I18nProvider.getIntlProvider();

const ERROR =
  "Unable to join the video call. May be the call is not scheduled yet. Please contact your health care provider.";

const cardStyle = {
  boxShadow: "0px 2px 8px rgba(0,0,0,0.5)",
  borderRadius: "6px",
  border: 0,
};

function getOtherParticipantName(participants, userId) {
  let filtered = participants.filter((p) => p.userId !== userId);
  if (filtered && filtered[0]) {
    return filtered[0].displayHumanName.text;
  }
  return null;
}

class ExistingRoomContainer extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    let { roomName } = this.props;
    this.setState({ loading: true });
    userService
      .fetchVideoRoom(roomName)
      .then((videoRoom) => {
        let { createdBy } = videoRoom;
        let isHost = createdBy.userId === this.props.userId;
        this.setState({ videoRoom, isHost, loading: false });

        if (videoRoom.provider === "WEBEX") {
          return;
        }

        let scheduledStart = videoRoom.scheduledStart;
        let scheduledEnd = videoRoom.scheduledEnd;

        if (scheduledEnd) {
          let diffInMin = moment().diff(moment(scheduledEnd), "minute");

          if (diffInMin >= 0) {
            this.setState({
              showCallButton: false,
              callEnded: true,
            });
            return;
          }
        }

        let showCallButton = !videoRoom.scheduledStart;
        if (scheduledStart) {
          let diffInMin = moment(videoRoom.scheduledStart).diff(
            moment(),
            "minute"
          );
          if (diffInMin <= 1) {
            showCallButton = true;
          } else {
            setTimeout(() => {
              this.setState({ showCallButton: true });
            }, diffInMin * 60 * 1000);
          }
        }
        this.setState({ showCallButton });
      })
      .catch(this.handleError);
  }

  onStartAnonymousCall = (e) => {
    let { roomName } = this.props;
    this.setState({ loading: true });

    doPollingToJoinCall(
      roomName,
      (data) => {
        this.setState(data);
      },
      moment(),
      this.handleError
    );
  };

  handleError = (error) => {
    this.setState({
      loading: false,
      error: error.message || ERROR,
    });
  };

  // showCallButton = () => {
  //   let { videoRoom } = this.state;
  //
  //   return (
  //     !videoRoom.scheduledStart ||
  //     (videoRoom.scheduledStart &&
  //       moment(videoRoom.scheduledStart).diff(moment(), "minute") <= 1)
  //   );
  // };

  render() {
    let {
      loading,
      token,
      error,
      participants,
      callNotReady,
      videoRoom,
      showCallButton,
      callEnded,
    } = this.state;

    let { userName, roomName } = this.props;

    if (loading) {
      return (
        <CallView>
          <div className="d-flex justify-content-center">
            <div style={{ margin: "100px auto", width: 100 }}>
              <MPSBarLoader />
            </div>
          </div>
        </CallView>
      );
    }

    if (error) {
      return (
        <Fragment>
          <CallView>
            <div className="d-flex justify-content-center mb-3">
              <UserIcon />
            </div>

            <p className="text-danger text-center my-4">{error}</p>
          </CallView>
          <div className="my-3 text-center">
            <GoToHome />
          </div>
        </Fragment>
      );
    }

    if (callNotReady) {
      return (
        <CallView>
          <div className="d-flex justify-content-center mb-3">
            <UserIcon />
          </div>

          <p className="font-lg text-center">
            {`Call with ${getOtherParticipantName(
              participants,
              this.props.userId
            )}, waiting for them to join.`}
          </p>
          <div className="my-3">
            <CallWaitTimer />
          </div>
        </CallView>
      );
    }

    if (callEnded) {
      return (
        <Fragment>
          <CallView>
            {userName && (
              <div className="d-flex justify-content-center mb-2 font-xl">
                {`Hello ${userName}`}
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center font-lg">
              <p className="text-danger">Call has already ended.</p>
            </div>
          </CallView>
          <div className="my-3 text-center">
            <GoToHome />
          </div>
        </Fragment>
      );
    }

    if (token) {
      return (
        <div
          className="row d-flex flex-column justify-content-center align-items-center"
          style={{ width: "100%" }}
        >
          <div className="col-sm-12">
            <Card style={cardStyle}>
              <CardBody>
                <VideoComponent
                  token={token}
                  roomName={roomName}
                  participants={participants}
                  otherParticipantName={getOtherParticipantName(
                    participants,
                    this.props.userId
                  )}
                  existing={true}
                  userRole={this.props.userRole}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      );
    }
    if (videoRoom) {
      if (videoRoom.provider === "WEBEX") {
        return (
          <PatientWebexMeeting
            sipAddress={roomName}
            roomNameAndUserId={roomName}
            auth
            isHost={this.state.isHost}
          />
        );
      }

      if (showCallButton) {
        return (
          <CallView>
            <div>
              {userName && (
                <div className="d-flex justify-content-center mb-2 font-lg">
                  {`Hello ${userName}, click here to join the video call with ${getOtherParticipantName(
                    videoRoom.participants,
                    this.props.userId
                  )}.`}
                </div>
              )}
              {isIOSChromeOrSafari() ? (
                <div className="d-flex justify-content-center">
                  Chrome/Firefox on iPhone and iPad are not supported. Please
                  copy the URL from address bar and open it in Safari browser.
                </div>
              ) : (
                <div className="d-flex justify-content-center my-4">
                  <MPSButton
                    color="green"
                    onClick={this.onStartAnonymousCall}
                    size="lg"
                  >
                    <Text content={Messages.join_video_call} />
                  </MPSButton>
                </div>
              )}
            </div>
          </CallView>
        );
      } else {
        return (
          <Fragment>
            <CallView>
              <div className="text-center">
                {userName && (
                  <div className="d-flex justify-content-center mb-2 font-xl">
                    {`Hello ${userName}`}
                  </div>
                )}
                <div className="mb-4 font-lg">
                  {`Video call with ${getOtherParticipantName(
                    videoRoom.participants,
                    this.props.userId
                  )} has not started yet.`}
                </div>

                <div className="mb-2 font-lg">Call Scheduled to Start at:</div>

                <div
                  style={{
                    fontSize: "38px",
                    color: "#3e515b",
                  }}
                >
                  {moment(videoRoom.scheduledStart).format("HH:mm")}
                </div>

                <div className="mb-4" style={{ fontSize: "22px" }}>
                  {new Date(videoRoom.scheduledStart).toLocaleDateString()}
                </div>

                <i>
                  This page will auto-refresh. A Join Now button will appear
                  when call is open.
                </i>
              </div>
            </CallView>
            <div className="my-3 text-center">
              <GoToHome />
            </div>
          </Fragment>
        );
      }
    }

    return null;
  }
}

function CallView({ children }) {
  return (
    <div
      className="row d-flex flex-column justify-content-center align-items-center"
      style={{ width: "100%" }}
    >
      <div className="col-sm-12 col-md-8">
        <Card style={cardStyle}>
          <CardBody>{children}</CardBody>
        </Card>
      </div>
    </div>
  );
}

const mapstatetoprops = (state, ownProps) => {
  return {
    roomName:
      ownProps.match && ownProps.match.params && ownProps.match.params.roomName
        ? ownProps.match.params.roomName
        : null,
    userId: state.user.data.userId,
    userName: state.user.data.loggedInUser.text || null,
    userRole: state.user.role,
  };
};

export default connect(mapstatetoprops)(ExistingRoomContainer);
